<script lang="ts">
import { useQuery } from '@tanstack/vue-query'
import Viewer from 'viewerjs'
import { BlogItems, Breadcrumb, RelatedArticles, SearchInput } from '~/pages-components/blog/components'
import 'viewerjs/dist/viewer.min.css'

function useArticle() {
  const router = useRouter()
  const blogId = toRef(() => {
    const _id = String(router.currentRoute.value.params?.blogId ?? '').match(/\d+$/)?.[0] ?? ''
    return Number(_id)
  })
  const { locale } = useI18n()

  const detailQuerier = useQuery({
    enabled: toRef(() => !!blogId.value && !import.meta.env.SSR),
    refetchOnWindowFocus: false,
    queryKey: [apisBlog.apiNewsDetail.id, locale, blogId],
    queryFn: () => apisBlog.apiNewsDetail({ news_id: blogId.value }, { errorMessageMode: undefined }),
    retry(failureCount, error) {
      if (error instanceof ApiError && error.code === 35000)
        return false
      return failureCount < 3
    },
  })

  const breadcrumbs = computed(() => {
    const category = detailQuerier.data.value?.category?.[0]
    const alias = (category?.alias || category?.name) ?? ''

    return [
      { name: $t('1J6d94jQiyf2iQ5ynjsR'), path: localePath({ path: `/blog` }) },
      { name: category?.name ?? '', path: decodeURIComponent(localePath({ path: `/blog/${alias}` })) },
    ]
  })

  // 如果没有此文章，则跳转到博客首页
  watch(detailQuerier.error, (error) => {
    if (error instanceof ApiError && error.code === 35000)
      window.location.href = localePath({ path: '/blog' })
  })

  return reactive({
    detail: detailQuerier.data,
    isLoading: detailQuerier.isFetching,
    breadcrumbs,
  })
}

function useImageViewer(article: Ref<HTMLElement | undefined>) {
  let viewer: Viewer

  watch(article, (article) => {
    if (!article)
      return

    viewer = new Viewer(article, {
      zIndex: 3000,
      backdrop: true,
      fullscreen: undefined,
      title: false,
      button: true,
      toolbar: {
        rotateLeft: true,
        rotateRight: true,
        next: true,
        prev: true,
        oneToOne: true,
      },
    })
  })

  onUnmounted(() => viewer?.destroy())
}
</script>

<script setup lang="ts">
const article = useArticle()
const $article = ref<HTMLElement>()

useImageViewer($article)

const i18nHead = useLocaleHead({ addSeoAttributes: true })
useHead({
  title: () => `${$t('1J6d94jQiyf2iQ5ynjsR')} - ${article.detail?.title ?? ''}`,
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [
    ...(i18nHead.value.meta || []),
    { name: 'description', content: () => article.detail?.summary ?? '' },
	  { property: 'og:image', content: () => article.detail?.covers ?? '' },
  ],
})
</script>

<template>
  <div>
    <div class="page-content-width new-detail-page">
      <!-- 加载中 -->
      <template v-if="!article.detail">
        <div class="h-[100px] flex-1 flex justify-center items-center">
          <i class="i-svg-spinners:180-ring-with-bg block text-[30px] color-[#3870f0]" />
        </div>
      </template>

      <!-- 文章 -->
      <template v-else>
        <div class="[grid-area:main] main">
          <div class="title flex justify-between">
            <Breadcrumb :breadcrumbs="article.breadcrumbs" />
            <time class="self-center text-[16px] text-[#8098BF]" pubdate>{{ $d((article.detail?.create_time ?? 0) * 1000) }}</time>
          </div>

          <h1 class="text-center my-[1em] lh-normal px-[40px] lt-tabletl:px-20px">{{ article.detail?.title }}</h1>
          <article ref="$article" class="content" v-html="article.detail?.content ?? ''" />
        </div>

        <!-- 搜索 -->
        <SearchInput class="[grid-area:search]" />

        <!-- 侧边栏 -->
        <div v-if="article.detail" class="[grid-area:sidebar] w-[300px] lt-tabletl:w-auto sidebar">
          <RelatedArticles class="related-articles" :blogId="article.detail.news_id" #="{ record }">
            <BlogItems.Style3
              :id="record.news_id"
              :title="record.title"
              :alias="record.alias"
              :cover="record.covers"
              :category="record.category?.[0].alias ?? ''"
            />
          </RelatedArticles>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.new-detail-page {
  display: grid;
  grid-template: 50px auto / 1fr auto;
  grid-template-areas: 'main search' 'main sidebar';
  gap: 30px;
  margin: 15px auto 20px;

  @media bp-lt-tabletl {
    grid-template: 40px auto auto / 1fr;
    grid-template-areas: 'search' 'main' 'sidebar';
    gap: 15px;
  }

  .main {
    flex: 1;
    color: #fff;
    background-color: #202c52;
    border-radius: 30px;

    @media bp-lt-tabletl {
      border-radius: 20px;
    }
  }

  .title{
    padding: 40px 40px 0;
    font-weight: bold;
    color: var(--first-text);
    background: linear-gradient(180deg, #2C3967, #202C52);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    @media bp-lt-tabletl {
      padding: 16px 16px 0;
    }
  }
}

.content {
  padding: 0 40px 40px;
  line-height: 1.5em;
  color: #b1bdd1;

  @media bp-lt-tabletl {
    padding: 0 16px 16px;
  }

  :deep(h1) {
    margin: 1em 0;
    font-size: 1.8rem;
    font-weight: 600;
  }

  :deep(h2) {
    margin: 1em 0;
    font-size: 1.4rem;
    font-weight: 600;

  }

  :deep(h3) {
    margin: 1em 0;
    font-size: 1.2rem;
    font-weight: 600;
  }

  :deep(p) {
    margin: 1em 0;
  }

  :deep(a) {
    color: #2B72E9;
    text-decoration: underline;

    &:hover {
      color: #3c7ded;
    }
  }

  :deep(ul) {
    margin: 1em 0 1em 2em;
    list-style: initial;
  }

  :deep(ol) {
    margin: 1em 0 1em 2em;
    list-style: initial;
  }

  :deep(li) {
    margin: 0.5em 0;
  }

  :deep(img) {
    max-width: 100%;
    height: auto !important;

    // 当屏幕过小时，覆盖富文本对图片设置的宽度，使其自适应显示
    @media bp-lt-tablet {
      &[style*='width:'] {
        width: 100% !important;
      }
    }
  }

  :deep(blockquote) {
    padding: 1.5em 1em;
    background-color: rgb(0 0 0 / 20%);
    border-left: 4px solid #3c7ded;
  }

  :deep(table) {
    width: 100% !important;
    max-width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 10px;
      text-align: left;
      border-bottom: 1px solid rgb(0 0 0 / 30%);
    }

    th {
      font-weight: bold;
      background-color: #19223C;
    }

    tr:nth-child(even) {
      background-color: rgb(0 0 0 / 10%);
    }

    tr:hover {
      background-color: rgb(0 0 0 / 20%);
    }
  }
}

.related-articles {
  :deep(.list) {
    display: grid;
    gap: 15px;

    @media bp-lt-tabletl {
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      gap: 30px;
    }

    @media bp-lt-tabletl {
      grid-template-columns: 1fr;
    }
  }
}

:global(.medium-zoom-overlay) {
  z-index: 100;
}

:global(.medium-zoom-image--opened) {
  z-index: 101;
}
</style>
