<script setup lang="ts">
import { reactivePick } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'

const props = withDefaults(defineProps<{
  blogId?: number
  count?: number
}>(), {
  count: 5,
})

const { locale } = useI18n()
const newsQuerier = useQuery({
  queryKey: [apisBlog.apiNewsRelated.id, locale, reactivePick(props, 'blogId')],
  queryFn: () => apisBlog.apiNewsRelated({ news_id: props.blogId }),
  select(data) {
    return data?.slice(0, 5)
  },
})
const news = toRef(() => newsQuerier.data.value ?? [])
</script>

<template>
  <div v-if="news.length" role="complementary" aria-labelledby="related-heading">
    <h2 class="text-[#8098BF] text-22px mb-1em">{{ $t('yw1qIjldk3ddcEuIEtq8') }}</h2>
    <ul class="list" role="list">
      <li v-for="item in news" :key="item.news_id" role="listitem">
        <slot v-bind="{ record: item }" />
      </li>
    </ul>
  </div>
</template>
